@font-face {
  font-family: 'Anton';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/Anton-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  src: local('Open Sans'), local('OpenSans'), url('./fonts/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/NotoSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: bold;
  src: url('./fonts/NotoSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Avenir Regular';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/Avenir-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Avenir Bold';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/Avenir-Bold.ttf') format('truetype');
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.font-avenir-regular {
  font-family: 'Avenir Regular';
}

.font-avenir-bold {
  font-family: 'Avenir Bold';
}

@keyframes pulse {
  50% {
    opacity: 0.3;
  }
}
